import React, { useState, useEffect } from "react";
import { Button, Col, Row, message } from "antd";
import { saveAs } from "file-saver";
import { FacebookShareButton } from "react-share";

const VideoPage = () => {
  const outputVideo = localStorage.getItem("task_result");
  const task_id = localStorage.getItem("task_id");
  const [cuurentPath, setCurrentPath] = useState("");
  const base_url = window.location.origin;
  const [width, setWidth] = useState(window.innerWidth);
  const [messageApi, contextHolder] = message.useMessage();
  // https://res.cloudinary.com/dkcey4lu8/video/upload/v1680875792/pvdexafhvit4cxhgki3i.mp4
  // https://res.cloudinary.com/dkcey4lu8/video/upload/v1679901925/full_c1nip4.mov
  // 37

  useEffect(() => {
    //
    setCurrentPath(`${base_url}/preview/${task_id}`);
  }, [cuurentPath, task_id, base_url]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const error = (msg) => {
    messageApi.open({
      type: "error",
      content: msg,
    });
  };

  const saveFile = () => {
    // check window width
    if (width <= 768) {
      saveFileMobile();
    } else {
      saveFileDesktop();
    }
  };

  const mp4toBlob = (mp4) =>
    new Promise((resolve) => {
      fetch(mp4).then((response) => resolve(response.blob()));
    });

  const saveFileDesktop = () => {
    if (outputVideo.includes(".mov")) {
      const file = new File([outputVideo], "MoochieHappyVoice.mp4", {
        type: "video/mp4",
      });

      saveAs(file, "MoochieHappyVoice.mp4");
    }

    if (outputVideo.includes(".mp4")) {
      saveAs(`${outputVideo}`, "MoochieHappyVoice.mp4");
    }
  };

  const saveFileMobile = async () => {
    if (!navigator.canShare) {
      error(`Your browser doesn't support the Web Share API1.`);
      return;
    }

    let media;

    if (outputVideo.includes(".mov")) {
      media = outputVideo;
    }

    if (outputVideo.includes(".mp4")) {
      media = await mp4toBlob(outputVideo);
    }

    const data = {
      files: [
        new File([media], "MoochieHappyVoice.mp4", {
          type: "video/mp4",
        }),
      ],
      title: "MoochieHappyVoice",
      text: "MoochieHappyVoice",
    };

    if (navigator.canShare(data)) {
      try {
        await navigator.share(data);
        error("Shared!");
      } catch (error) {
        error(`Error: ${error.message}`);
      }
    } else {
      error(`Your system doesn't support sharing these files.`);
    }
  };

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  return (
    <div className="backgroundVideo">
      {contextHolder}
      <div className="boxVideo">
        <div className="divVideo1">
          <center>
            <div className="marginHeadeVideo">
              <b className="headVideo1">ขอบคุณที่ร่วมสนุกกับ</b>
              <br></br>
              <b className="headVideo2">MOOCHIE</b>
            </div>

            <div className="head2">อย่าลืมแชร์คลิปอวดความน่ารักด้วยนะ</div>
          </center>
        </div>
        <div className="divUploadVideo">
          <video className="video_output" autoPlay muted controls>
            <source src={outputVideo} type="video/mp4" />
          </video>
        </div>
        <div className="ButtonBox">
          <center>
            <Button className="buttomDownload" onClick={saveFile}></Button>
            <br></br>
            {/* <Button className="buttomShare" ></Button> */}
            <FacebookShareButton
              url={cuurentPath}
              quote="https://moochiehappyvoice.com"
              hashtag="#MoochieHappyVoiceSongKran"
              className="buttomShare"
            ></FacebookShareButton>
          </center>
          <br></br>
          <Row className="boxPetsImg">
            <Col span={10}>
              <div className="V_ImgCat"></div>
            </Col>
            <Col span={14}>
              <div className="V_ImgDog"></div>
            </Col>
          </Row>
        </div>

        <br></br>
      </div>
      <div style={{ height: "100px" }}></div>
    </div>
  );
};

export default VideoPage;
