import { Route, Routes } from "react-router-dom";
import IndexPage from "./pages/index_page";
import VideoPage from "./pages/video_page";
import "./App.css";
import "antd/dist/reset.css";
import LoadingImage from "./pages/loadingImg";
import Preview from "./pages/Preview";

function App() {
  return (
    <Routes>
      <Route path="/" exact={true} element={<IndexPage />} />
      <Route path="/index_page" element={<IndexPage />} />
      <Route path="/video_page" element={<VideoPage />} />
      <Route path="/loadingImg" element={<LoadingImage />} />
      <Route path="/preview/:id" element={<Preview />} />
    </Routes>
  );
}

export default App;
