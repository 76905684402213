import React, { useState, useRef } from "react";
import { Col, Row, Button } from "antd";
import { CircleStencil, Cropper } from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";
import { uploadImage, process } from "../functions/uploadImg";
import Resizer from "react-image-file-resizer";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";

const IndexPage = () => {
  const cropperRef1 = useRef(null);
  const inputRef1 = useRef(null);
  const cropperRef2 = useRef(null);
  const inputRef2 = useRef(null);
  const [src1, setSrc1] = useState("");
  const [src2, setSrc2] = useState("");
  const [loading, setLoading] = useState(false);
  const [defaultVideo, setDefaultVideo] = useState(false);
  const [checked, setChecked] = React.useState(false);

  localStorage.removeItem("task_result");
  localStorage.removeItem("task_id");


  const b64toBlob = (b64Data) => {
    // Split into two parts
    const parts = b64Data.split(";base64,");

    // Hold the content type
    const imageType = parts[0].split(":")[1];

    // Decode Base64 string
    const decodedData = window.atob(parts[1]);

    // Create UNIT8ARRAY of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length);

    // Insert all character code into uInt8Array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    // Return BLOB image after conversion
    return new Blob([uInt8Array], { type: imageType });
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64",
        500,
        500
      );
    });

  const onCrop = async (e) => {
    e.preventDefault();
    setLoading(true);
    setDefaultVideo(true)
    // check dog cat
    const b64Dog = cropperRef1.current.getCanvas()?.toDataURL()
      ? cropperRef1.current.getCanvas()?.toDataURL()
      : "";
    const b64Cat = cropperRef2.current.getCanvas()?.toDataURL()
      ? cropperRef2.current.getCanvas()?.toDataURL()
      : "";

    // tranform to blob
    let blobDog = [];
    let blobCat = [];

    if (b64Dog !== "") {
      blobDog = b64toBlob(b64Dog);
    }

    if (b64Cat !== "") {
      blobCat = b64toBlob(b64Cat);
    }

    // resizer 500 x 500
    let resizerDog = "";
    let resizerCat = "";
    if (b64Dog !== "") {
      resizerDog = await resizeFile(blobDog);
    }

    if (b64Cat !== "") {
      resizerCat = await resizeFile(blobCat);
    }

    let allUploadedImages = [];

    allUploadedImages.push({ type: "d", url: resizerDog });
    allUploadedImages.push({ type: "c", url: resizerCat });

    let UrlDog = "";
    let UrlCat = "";

    if (b64Dog !== "") {
      let data = new FormData();
      data.append("file", resizerDog);
      data.append("upload_preset", process.env.REACT_APP_PRESET_NAME);
      data.append("cloud_name", process.env.REACT_APP_CLOUD_NAME);
      data.append("folder", "HappyVoiceVideo");

      try {
        // save to api
        const dog = await uploadImage(data);
        UrlDog = dog.data.secure_url;
      } catch (error) {
        // setLoading(false);
        console.log("ERROR :", error);
      }
    }

    if (b64Cat !== "") {
      let data = new FormData();
      data.append("file", resizerCat);
      data.append("upload_preset", process.env.REACT_APP_PRESET_NAME);
      data.append("cloud_name", process.env.REACT_APP_CLOUD_NAME);
      data.append("folder", "HappyVoiceVideo");

      try {
        // save to api
        const cat = await uploadImage(data);
        UrlCat = cat.data.secure_url;
      } catch (error) {
        // setLoading(false);
        console.log("ERROR :", error);
      }
    }

    // console.log("res :", UrlDog);
    // console.log("res :", UrlCat);

    process(UrlDog, UrlCat)
      .then((res) => {
        setLoading(false);
        // console.log("res :", res);
        localStorage.setItem("Task_ID", res.data.task_id);
        // console.log("DATATASK",res.data.task_id)
        window.location.href = "loadingImg";
      })
      .catch((err) => {
        setLoading(false);
        console.log("ERROR :", err);
      });
  };

  const onUpload1 = (blob) => {
    // onReset();
    // setMode("crop");
    setSrc1(blob);
  };
  const onUpload2 = (blob) => {
    // onReset();
    // setMode("crop");
    setSrc2(blob);
  };

  const onLoadImage1 = (event) => {
    const { files } = event.target;
    // Ensure that you have a file before attempting to read it
    if (files && files[0]) {
      if (onUpload1) {
        onUpload1(URL.createObjectURL(files[0]));
      }
    }
    // Clear the event target value to give the possibility to upload the same image:
    event.target.value = "";
  };

  const onLoadImage2 = (event) => {
    const { files } = event.target;
    // Ensure that you have a file before attempting to read it
    if (files && files[0]) {
      if (onUpload2) {
        onUpload2(URL.createObjectURL(files[0]));
      }
    }
    // Clear the event target value to give the possibility to upload the same image:
    event.target.value = "";
  };

  const handleClick = (type) => {
    if (type === "dog") {
      inputRef1.current.click();
    } else if (type === "cat") {
      inputRef2.current.click();
    }
  };

  const handleCheckChange = () => {
    setChecked(!checked);
    setDefaultVideo(!checked)

    if(checked === false){
      setSrc1("");
      setSrc2("");
    }

  };

  return (
    <div className="background">
      <center>
        <div className="marginHeader">
          <b className="headText1">MOOCHIE</b>
          <br></br>
          <b className="headText2">HAPPY VOICE</b>
        </div>
        <div className="head2">สร้างคลิปสุดคิวท์ จากน้องหมาน้องแมวของคุณ</div>
        <br></br>
        <div className="divUploadImg">
          <div className="upImgDog">
            <Cropper
              ref={cropperRef1}
              stencilComponent={CircleStencil}
              src={src1}
              style={{
                height: "100%",
                background: `url(${require("../img/1_upload/upload_DOG.png")}) no-repeat`,
                backgroundSize: "cover",
              }}
            />
            <Button
              disabled={defaultVideo}
              icon={<UploadOutlined />}
              onClick={() => handleClick("dog")}
            >
              อัพโหลด
            </Button>
            <input
              ref={inputRef1}
              type="file"
              hidden
              accept="image/*"
              onChange={onLoadImage1}
              className="image-editor-navigation__upload-input"
            />
          </div>
          <div className="upImgCat">
            {/* <Button className='editButton'>แก้ไข</Button> */}
            <Cropper
              ref={cropperRef2}
              stencilComponent={CircleStencil}
              src={src2}
              style={{
                height: "100%",
                background: `url(${require("../img/1_upload/upload_CAT.png")}) no-repeat`,
                backgroundSize: "cover",
              }}
            />
            <Button
              disabled={defaultVideo}
              icon={<UploadOutlined />}
              onClick={() => handleClick("cat")}
            >
              อัพโหลด
            </Button>
            <input
              ref={inputRef2}
              type="file"
              hidden
              accept="image/*"
              onChange={onLoadImage2}
              className="image-editor-navigation__upload-input"
            />
          </div>
        </div>
      </center>

      <div className="checkbox">
        <input 
        checked={checked}
        onChange={handleCheckChange}
        style={{width:'25px',height:'25px',verticalAlign:'middle',marginRight:'5px'}}
        type="checkbox" /> 
        <b>  Video from MOOCHIE</b>
      </div>

      <Row className="footer">
        <Col span={8}>
          <div className="imgDog"></div>
        </Col>
        <Col span={8}>
          <center>
            {loading ? (
              <LoadingOutlined className="text-danger" />
            ) : (
              <Button className="Submit" onClick={onCrop}>
                ยืนยัน
              </Button>
            )}

            {/* <Button className='Submit'>ยืนยัน</Button> */}
          </center>
        </Col>
        <Col span={8}>
          <div className="imgCat"></div>
        </Col>
      </Row>
    </div>
  );
};

export default IndexPage;
