import { Button, Col, Row } from "antd";
import React, { useState, useEffect } from "react";

import { useParams } from "react-router";
import { getPreview } from "../functions/uploadImg";
import { saveAs } from "file-saver";
import { ArrowLeftOutlined } from "@ant-design/icons";

const Preview = () => {
  const [video, setVideo] = useState("");

  const { id } = useParams();
  // const id = 37;

  useEffect(() => {
    getPreview(id)
      .then((res) => {
        // get urlVideo
        const data = res.data.url;
        // console.log(data)
        setVideo(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);


  const saveFile = () => {
    saveAs(`${video}`, "MoochieHappyVoice.mp4");
  };

  const redirectToMain = (e) => {
    e.preventDefault();
    window.location.href = "https://moochiehappyvoice.com";
  };

  return (
    <div className="backgroundVideo">
      <div className="boxVideo">
        <div className="divVideo1">
          <center>
            <div className="marginHeadeVideo">
              <b className="headVideo1">ขอบคุณที่ร่วมสนุกกับ</b>
              <br></br>
              <b className="headVideo2">MOOCHIE</b>
            </div>

            <div className="head2">อย่าลืมแชร์คลิปอวดความน่ารักด้วยนะ</div>
          </center>
        </div>
        <div className="divUploadVideo">
          {/* <video className="video_output" autoPlay muted controls>
            <source src={video} type="video/mp4" />
          </video> */}
          <video
            src={video}
            controls
            width="100%"
            className="video_output"
            muted
          >
            Sorry, your browser doesn't support embedded videos.
          </video>
        </div>
        <div className="ButtonBox">
          <center>
            <Button className="buttomDownload" onClick={saveFile}></Button>
            <br></br>
            <Button
              onClick={redirectToMain}
              icon={<ArrowLeftOutlined />}
              size="large"
            >
              เข้าร่วมกิจกรรม
            </Button>
          </center>
          <br></br>
          <Row className="boxPetsImg">
            <Col span={10}>
              <div className="V_ImgCat"></div>
            </Col>
            <Col span={14}>
              <div className="V_ImgDog"></div>
            </Col>
          </Row>
        </div>

        <br></br>
      </div>
    </div>
  );
};

export default Preview;
