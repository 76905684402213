
import React, { useEffect } from "react";
import { SendTaskID } from "../functions/uploadImg";
import { LoadingOutlined } from "@ant-design/icons";

const LoadingImage = () => {

    const TID = localStorage.getItem("Task_ID");
    // const [taskStatus, setTaskStatus]=useState("");

    useEffect(() => {
        // loadStatus();
        let taskProcess = setInterval(() => {
            SendTaskID(TID).then((res) => {
                // setTaskStatus(res.data.task_status);

                if(res.data.task_status ==="SUCCESS"){
                    // console.log("success", res)
                    if(res.data.id){
                        localStorage.setItem("task_id", res.data.id[0].id);
                    } else {
                        localStorage.setItem("task_id", "0");
                    }
                    localStorage.setItem("task_result",res.data.task_result);
                    clearInterval(taskProcess);
                    window.location.href = "video_page";
                }
            });
        }, 3000);

        return () => {
            clearInterval(taskProcess);
          };

    }, [TID])

    return (
        <div className='background'>
            <center>
                <div className='marginHeader'>
                    <b className='headText1'>MOOCHIE</b>
                    <br></br>
                    <b className='headText2'>HAPPY VOICE</b>
                </div>
                <div className='head2'>สร้างคลิปสุดคิวท์ จากน้องหมาน้องแมวของคุณ</div>
                <br></br>
                <div className='divLoading'>
                    
                <LoadingOutlined style={{fontSize:'160px',color:'#20B2AA',margin:'5%'}} /> 
                <br></br>
                 <b style={{fontSize:'40px',color:'#696969'}}> กรุณารอสักครู่. . .</b>

                </div>

            </center>
        </div>

    )

};
export default LoadingImage;