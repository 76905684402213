import axios from "axios";

export const uploadImage = async (data) => {
  return await axios.post(
    `https://api.cloudinary.com/v1_1/dgszokw88/image/upload`,
    data
  );
}
  

export const addImg = async (body) => {
  return await axios.post(`https://api.moochiehappyvoice.com/process`, body);
};

export const process = async (dog, cat) => {
  return await axios.post(`https://api.moochiehappyvoice.com/process`, {
    dog_img: dog,
    cat_img: cat,
  });
};

export const SendTaskID = async (TID) => {
  return await axios.get(`https://api.moochiehappyvoice.com/status`, {
    params: {
      task_id: TID,
    },
  });
};

export const getPreview = async (id) => {
  return await axios.get(`https://api.moochiehappyvoice.com/get-video`, {
    params: {
      id: id,
    },
  });
};